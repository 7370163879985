import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { withNumbers } from './with-numbers';
import Paragraph from './paragraph';

const SnapshotItem = (props) => {
    console.log(props.paragraphs);
    if (!props.paragraphs.length) return null;

    return (
        <div className="gc-snapshot-content">
            {props.paragraphs.map((paragraph, b) => {
                return <Paragraph id={"p" + b} key={"p-" + b} words={paragraph.words} />
            })}
        </div>
    )
};

// HOC this sucker
const SnapshotWithNumbers = withNumbers(SnapshotItem);

const SnapshotList = (props) => (
    <div className="gc-snapshot-details">
        <h3 className='gc-section-heading'>Snapshots</h3>

        <div className="gc-snapshot-container clearfix">
            { props.cipherSnapshots.length ?
                props.cipherSnapshots.map((snapshot, a) => (
                    <SnapshotWithNumbers {...snapshot} key={`gc-snapshot-${a}`} close={props.deleteSnapshot} index={a} />
                )) : (
                    <>Press the "Enter" key to create a saved snapshot of text.</>
                )
            }
        </div>
    </div>
);

const mapDispatchToProps = (dispatch, props) => {
    return {
        deleteSnapshot: (i) => {
            dispatch(actions.deleteSnapshot(i));
        }
    }
};

export default connect(null, mapDispatchToProps)(SnapshotList);