import React from 'react';
import { connect } from 'react-redux';
import './number-reduction.scss';

import Number from './number';

const NumberReduction = (props) => {
    if (props.showNumerology) {
        return (
            <div className="gc-number-reduction">
                {/* <span className='gc-label'>numerology</span> */}

                <span className='gc-number-divider'>&nbsp;/&nbsp;</span>

                <Number number={props.number} />
            </div>
        )
    } else {
        return null;
    }
};

const mapStateToProps = state => ({
    showNumerology: state.showNumerology
});

export default connect(mapStateToProps, null)(NumberReduction);