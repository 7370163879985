import React from 'react';
import { connect } from 'react-redux';
import Number from './number';
import './word.scss';

const Word = (props) => {

    if (!props.value) {
        return null;
    }

    return (
        <div className="gc-word clearfix">
            <div className="gc-letters clearfix">
                {
                    props.letters.map((letter, index) => (
                        <div key={props.id + '-l' + index} className="gc-letter">
                            <span className="gc-letter-alpha">{letter[0]}</span>

                            {LetterValue(props.showLetterValues, letter[1])}
                        </div>
                    ))
                }
            </div>

            <div className="gc-word-value">
                <span className="gc-letter-value"><Number number={props.value} /></span>
            </div>
        </div>
    )
}

const LetterValue = (showLetterValues, letterValue) => {
    if (showLetterValues) {
        return (
            <span className="gc-letter-value">
                <Number number={letterValue} />
            </span>
        )
    } else {
        return null;
    }
}

const mapStateToProps = state => ({
    showLetterValues: state.showLetterValues
})

export default connect(mapStateToProps)(Word);