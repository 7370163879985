import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Input, Form, TextArea } from 'semantic-ui-react';
import './user-input.scss';

const UserInput = (props) => {
    if (props.useBigInput) {
        return (
            <div className="gc-user-input-container">
                <Form>
                    <TextArea
                        placeholder='Type here...'
                        value={props.text}
                        onChange={(e) => props.onChange(e)}
                    />
                </Form>
            </div>
            
        )
    } else {
        return (
            <div className="gc-user-input-container">
                <Input
                    fluid
                    placeholder='Type here...'
                    value={props.text}
                    onChange={(e) => props.onChange(e)}
                    onKeyPress={(e) => props.onKeyPress(e)}
                />
            </div>
        )
    }
}

UserInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string
}

const mapStateToProps = (state, props) => {
    return {
        useBigInput: state.useBigInput,
        text: state.text
    }
}

// Map store dispatch to component props
const mapDispatchToProps = (dispatch, props) => {
    return {
        onChange: (e) => {
            dispatch(actions.updateText(e.target.value));
        },
        onKeyPress: (e) => {
            if (e.key === 'Enter') {
                dispatch(actions.takeSnapshot());
            }
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserInput);