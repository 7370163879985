import React from 'react';

import Word from './word';

const Paragraph = (props) => (
    <div className="gc-paragraph clearfix">
        {props.words.map((word, i) => (
            <Word
                id={props.id + '-w' + i}
                key={props.id + '-w' + i}
                letters={word.letters}
                value={word.value}
            />
        ))}
    </div>
);

export default Paragraph;