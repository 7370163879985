/**
 * action types
 */

export const CHANGE_VIEW = 'CHANGE_VIEW';

export const UPDATE_TEXT = 'UPDATE_TEXT';

export const UPDATE_ACTIVE_CIPHER = 'UPDATE_ACTIVE_CIPHER';

export const UPDATE_OVERRIDE_RULES = 'UPDATE_OVERRIDE_RULES';

export const UPDATE_SETTINGS_ACTIVE = 'UPDATE_SETTINGS_ACTIVE';

export const TOGGLE_USER_INPUT_FIELD = 'TOGGLE_USER_INPUT_FIELD';

export const TOGGLE_SHOW_NUMEROLOGY = 'TOGGLE_SHOW_NUMEROLOGY';

export const TOGGLE_SHOW_LETTER_VALUES = 'TOGGLE_SHOW_LETTER_VALUES';

export const TOGGLE_SHOW_CIPHER_CHARTS = 'TOGGLE_SHOW_CIPHER_CHARTS';

export const CHANGE_STUDY_NUMBER = 'CHANGE_STUDY_NUMBER';

export const TAKE_SNAPSHOT = 'TAKE_SNAPSHOT';

export const DELETE_SNAPSHOT = 'DELETE_SNAPSHOT';

/**
 * action creators
 */

export function changeView(view) {
    return {
        type: CHANGE_VIEW,
        view: view
    }
}

export function updateText(text) {
    return {
        type: UPDATE_TEXT,
        text: text
    }
}

export function updateActiveCipher(payload) {
    return {
        type: UPDATE_ACTIVE_CIPHER,
        payload: payload
    }
}

export function updateOverrideRules(payload) {
    return {
        type: UPDATE_OVERRIDE_RULES,
        payload: payload
    }
}

export function updateSettingsActive(isActive) {
    return {
        type: UPDATE_SETTINGS_ACTIVE,
        isActive: isActive
    }
}

export function toggleUserInputField(payload) {
    return {
        type: TOGGLE_USER_INPUT_FIELD,
        payload: payload
    }
}

export function toggleShowNumerology(payload) {
    return{
        type: TOGGLE_SHOW_NUMEROLOGY,
        payload: payload
    }
}

export function toggleShowLetterValues(payload) {
    return {
        type: TOGGLE_SHOW_LETTER_VALUES,
        payload: payload
    }
}

export function toggleShowCipherCharts(payload) {
    return {
        type: TOGGLE_SHOW_CIPHER_CHARTS,
        payload: payload
    }
}

export function changeStudyNumber(payload) {
    return {
        type: CHANGE_STUDY_NUMBER,
        payload: payload
    }
}

// No need for payload; just moving data around in reducers.
export function takeSnapshot() {
    return {
        type: TAKE_SNAPSHOT
    }
}

/**
 * @param {int} i - The index of the payload item to delete.
 */
export function deleteSnapshot(i) {
    return {
        type: DELETE_SNAPSHOT,
        payload: i

    }
}