import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Modal } from 'semantic-ui-react';
import Settings from '../views/settings';
import './settings-modal.scss';

const SettingsModal = (props) => (
    <Modal
        onClose={() => props.updateSettingsActive(false)}
        open={props.settingsActive}
        size='tiny'
    >
        <Modal.Header>
            <div className="clearfix">
                <div className="gen-modal-header-title">Settings</div>

                <div className="gen-modal-header-icon">
                    <div
                        className="gen-icon-close"
                        onClick={() => props.updateSettingsActive(false)}
                    ></div>
                </div>
            </div>
        </Modal.Header>

        <Modal.Content>
            <Settings />
        </Modal.Content>
    </Modal>
);

const mapStateToProps = state => ({
    settingsActive: state.settingsActive
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        updateSettingsActive: (isActive) => {
            dispatch(actions.updateSettingsActive(isActive));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsModal);