import * as actions from './actions.js';
import Dictionary from './data/dictionary';

const INITIAL_CIPHER_VALUES = {
    isActive: false,
    name: '',
    overrides: {},
    paragraphs: [{
        words: [{
            letters: [],
            value: 0
        }]
    }],
    totalValue: 0,
    totalValueReduction: 0
};

function GcAppReducers(state = getInitialState(), action) {
    switch (action.type) {
        case actions.CHANGE_VIEW:
            return {
                ...state,
                view: action.view
            }
        case actions.UPDATE_SETTINGS_ACTIVE:
            return {
                ...state,
                settingsActive: action.isActive
            }
        case actions.TOGGLE_USER_INPUT_FIELD:
            return {
                ...state,
                useBigInput: action.payload.checked
            }

        case actions.TOGGLE_SHOW_NUMEROLOGY:
            return {
                ...state,
                showNumerology: action.payload.checked
            }

        case actions.TOGGLE_SHOW_LETTER_VALUES:
            return {
                ...state,
                showLetterValues: action.payload.checked
            }

        case actions.TOGGLE_SHOW_CIPHER_CHARTS:
            return {
                ...state,
                showCipherCharts: action.payload.checked
            }

        case actions.UPDATE_TEXT:
            return {
                ...state,
                text: action.text,
                ciphers: parseAllCiphers(state.ciphers, action.text)
            }

        case actions.UPDATE_ACTIVE_CIPHER:
            const newCipherList = {
                ...state.ciphers,
                [action.payload.cipher]: {
                    ...state.ciphers[action.payload.cipher],
                    isActive: state.ciphers[action.payload.cipher].isActive ? false : true,
                }
            };

            return {
                ...state,
                ciphers: parseAllCiphers(newCipherList, state.text),
                cipherSnapshots: reParseSnapshots(state.savedPhrases, newCipherList)
            }

        case actions.UPDATE_OVERRIDE_RULES:
            let cipher = action.payload.cipher;
            let actionOverrides = action.payload.overrides;
            let updatedOverrides = {};
            let stateCipher = state.ciphers[cipher];

            actionOverrides.map(override => {
                updatedOverrides[override] = {
                    ...stateCipher.overrides[override],
                    isActive: !stateCipher.overrides[override].isActive
                }
            });

            const updatedCipherList = {
                ...state.ciphers,
                [cipher]: {
                    ...stateCipher,
                    overrides: {
                        ...stateCipher.overrides,
                        ...updatedOverrides
                    }
                }
            };

            return {
                ...state,
                ciphers: parseAllCiphers(updatedCipherList, state.text),
                cipherSnapshots: reParseSnapshots(state.savedPhrases, updatedCipherList)
            }

        case actions.CHANGE_STUDY_NUMBER:
            return {
                ...state,
                studyNumber: action.payload
            }

        case actions.TAKE_SNAPSHOT:
            let savedPhrases = [...state.savedPhrases, state.text];
            let r1 = {
                ...state,
                savedPhrases: savedPhrases,
                cipherSnapshots: addSnapshot(state.cipherSnapshots, state.ciphers)
            };

            return r1;

        case actions.DELETE_SNAPSHOT:
            let index = action.payload;
            let r = {
                ...state,
                savedPhrases: [...state.savedPhrases.slice(0, index), ...state.savedPhrases.slice(index + 1)],
                cipherSnapshots: deleteSnapshot(state.cipherSnapshots, state.ciphers, index)
            };

            return r;

        default:
            return state;
    }
}

/**
 * We are creating the initial, empty `ciphers` object from the list of ciphers
 * in our Dictionary. The first four are active by default.
 * 
 * @return {Object} - returns the default state for initializing our redux
 * store.
 */
function getInitialState() {
    const initialState = {
        settingsActive: false,
        showNumerology: true,
        showLetterValues: false,
        showCipherCharts: true,
        studyNumber: null,
        text: '',
        useBigInput: false,
        view: 'list',
        ciphers: {},
        savedPhrases: [],
        cipherSnapshots: {}
    };
    const cipherList = Dictionary.ciphers;
    let index = 0;

    for (let cipher in cipherList) {
        if (!cipherList.hasOwnProperty(cipher)) {
            return;
        }

        let dictionaryOverrides = Dictionary.ciphers[cipher].overrides;
        let overrides = {};

        if (Object.keys(dictionaryOverrides).length > 0) {
            for (let override in dictionaryOverrides) {
                overrides[override] = {
                    isActive: false,
                    name: dictionaryOverrides[override].name,
                    linkTo: dictionaryOverrides[override].linkTo
                }
            }
        }

        // Constitute cipher dictionary object
        initialState.ciphers[cipher] = {
            ...INITIAL_CIPHER_VALUES,
            isActive: index < 4 ? true : false, // first four ciphers active by default
            name: cipherList[cipher].name,
            overrides: overrides
        };

        // Constitute cipherSnapshots dictionary object
        initialState.cipherSnapshots[cipher] = [];

        index++;
    }

    return initialState;
}

/**
 * This is the brain of the whole app. Take any string and parse it through
 * all active ciphers, constructing a model of state and data for every view
 * in the app as we go.
 * 
 * @param {Object} cipherList - The current list of ciphers. This method
 * will update the current list based on new text inputs.
 * 
 * @param {String} text - A string to be parsed.
 * 
 * @return {Object} - An updated map of ciphers and their associated, parsed
 * text.
 */
function parseAllCiphers(cipherList, text) {
    let parsedValues = {};

    // Parse all values for each cipher
    for (let cipher in cipherList) {

        // Make sure this property is a cipher
        if (!cipherList.hasOwnProperty(cipher)) {
            continue;
        }

        // Make sure the cipher is active before parsing, else early return,
        // setting text and values to defaults.
        if (!cipherList[cipher].isActive) {

            // TODO: initial state object into const or func
            parsedValues[cipher] = ({
                ...cipherList[cipher],
                paragraphs: [{
                    words: [{
                        letters: [],
                        value: 0
                    }]
                }],
                snapshots: [],
                totalValue: 0,
                totalValueReduction: 0
            });

            continue;
        }

        let parsedText = Dictionary.parseText(cipher, text, cipherList[cipher].overrides);

        // Nested it under the appropriate cipher in parsedValues
        parsedValues[cipher] = {
            ...cipherList[cipher],
            paragraphs: parsedText.paragraphs,
            totalValue: parsedText.totalValue,
            totalValueReduction: parsedText.totalValueReduction
        };
    }

    return parsedValues;
}

/**
 * Snapshots are stored in a separate, key accessible object store that mimics
 * `ciphers`. They are stored separately because ciphers are updated on every
 * keypress, and we don't need to be updating the snapshot store that
 * frequently; it's unnecessary overhead.
 * 
 * NOTE that whereas ciphers is an object  where each key is a single cipher
 * object, snapshots is an object where each key is an array of cipher objects.
 * 
 * @param {Object} snapshots - An Object where each key is an array of saved
 * cipher objects. The order of saved objects in each array exactly mirrors the
 * order of saved strings in `savedPhrases`.
 * 
 * @param {Object} ciphers - Our core cipher object, a dictionary model.
 * 
 * @return {Object} - The updated snapshots dictionary object.
 */
function addSnapshot(snapshots, ciphers) {
    let newSnapshots = {};

    // Parse all values for each cipher
    for (let cipher in ciphers) {

        // Make sure this property is a cipher
        if (!ciphers.hasOwnProperty(cipher)) {
            continue;
        }

        if (ciphers[cipher].isActive) {
            newSnapshots[cipher] = [...snapshots[cipher], {
                paragraphs: ciphers[cipher].paragraphs,
                totalValue: ciphers[cipher].totalValue,
                totalValueReduction: ciphers[cipher].totalValueReduction
            }];

        } else {
            // clean up inactive snapshots
            newSnapshots[cipher] = [];
        }
    }

    return newSnapshots;
}

/**
 * @param {String[]} savedPhrases - An array of strings, the text chunks that
 * have been saved.
 * 
 * @param {Object} snapshots - An Object where each key is an array of saved
 * cipher objects. The order of saved objects in each array exactly mirrors the
 * order of saved strings in `savedPhrases`.
 * 
 * @param {Object} ciphers - Our core cipher object, a dictionary model.
 * 
 * @param {Bool} parseCiphers - Whether or not we need to re-parse some or all of
 * ciphers being assessed. For example, when taking a snapshot, we can simply
 * save a copy of each active cipher object. When activating a new cipher,
 * however, we need to parse every string in `savedPhrases` for that cipher.
 * 
 * @return {Object} - A new, re parsed snapshots dictionary object.
 */
function reParseSnapshots(savedPhrases, ciphers) {
    let newSnapshots = {};

    // Parse all values for each cipher
    for (let cipher in ciphers) {

        // Make sure this property is a cipher
        if (!ciphers.hasOwnProperty(cipher)) {
            continue;
        }

        if (ciphers[cipher].isActive) {
            let parsedText = '';

            newSnapshots[cipher] = savedPhrases.map(text => {
                parsedText = Dictionary.parseText(cipher, text, ciphers[cipher].overrides);

                // Nested it under the appropriate cipher in parsedValues
                return {
                    paragraphs: parsedText.paragraphs,
                    totalValue: parsedText.totalValue,
                    totalValueReduction: parsedText.totalValueReduction
                };
            });

        } else {
            // If not active, the cipher is alway an empty array
            newSnapshots[cipher] = [];
        }
    }

    return newSnapshots;
}

/**
 * @param {Object} snapshots - Dictionary object, the current snapshots.
 * 
 * @param {Object} ciphers - Dictionary object, the current cipher list.
 * 
 * @param {Int} i - The index of the snapshot item to remove.
 * 
 * @return {Object} - The new snapshots dictionary object with specified item
 * removed.
 */
function deleteSnapshot(snapshots, ciphers, i) {
    let newSnapshots = {}

    // Parse all values for each cipher
    for (let cipher in ciphers) {

        // Make sure this property is a cipher
        if (!ciphers.hasOwnProperty(cipher)) {
            continue;
        }

        // Make sure the cipher is active
        if (ciphers[cipher].isActive) {

            // Remove item at index.
            newSnapshots[cipher] = [...snapshots[cipher].slice(0, i), ...snapshots[cipher].slice(i + 1)];

        } else {
            newSnapshots[cipher] = [];
        }
    }

    return newSnapshots;
}

export default GcAppReducers;