import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Checkbox } from 'semantic-ui-react';

import ItemSettingsCipher from '../components/item-settings-cipher';

const Settings = (props) => (
    <div className="settings-view">
        <h3>Interface Options</h3>

        <ul className="gc-list gc-list-spacious">
            <li>
                <Checkbox
                    toggle
                    label='Use large text input.'
                    onChange={(e, data) => props.toggleUserInputField(e, data)}
                    checked={props.useBigInput}
                />
            </li>

            <li>
                <Checkbox
                    toggle
                    label='Show numerology value.'
                    onChange={(e, data) => props.toggleShowNumerology(e, data)}
                    checked={props.showNumerology}
                />
            </li>

            <li>
                <Checkbox
                    toggle
                    label='Show letter values for input text.'
                    onChange={(e, data) => props.toggleShowLetterValues(e, data)}
                    checked={props.showLetterValues}
                />
            </li>

            <li>
                <Checkbox
                    toggle
                    label='Show cipher chart in details.'
                    onChange={(e, data) => props.toggleShowCipherCharts(e, data)}
                    checked={props.showCipherCharts}
                />
            </li>
        </ul>

        <h3>Active Ciphers</h3>

        <ul className="gc-list gc-list-compact">
            {Object.entries(props.ciphers).map(([cipher, cipherProps]) => {
                return (
                    <ItemSettingsCipher key={cipher} {...cipherProps} cipher={cipher} />
                )
            })}
        </ul>
    </div>
)

const mapStateToProps = state => ({
    ciphers: state.ciphers,
    showNumerology: state.showNumerology,
    useBigInput: state.useBigInput,
    showLetterValues: state.showLetterValues,
    showCipherCharts: state.showCipherCharts
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        toggleUserInputField: (e, data) => {
            dispatch(actions.toggleUserInputField(data))
        },
        toggleShowNumerology: (e, data) => {
            dispatch(actions.toggleShowNumerology(data))
        },
        toggleShowLetterValues: (e, data) => {
            dispatch(actions.toggleShowLetterValues(data))
        },
        toggleShowCipherCharts: (e, data) => {
            dispatch(actions.toggleShowCipherCharts(data))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);