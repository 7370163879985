import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Checkbox } from 'semantic-ui-react';

const OptionCipherOverride = (props) => {

    // Return without rendering if there are no overrides.
    if (Object.keys( props.overrides ).length === 0) {
        return null;
    }

    let renderedOverrides = {};

    return (
        <ul className="gc-list gc-list-compact">
            {
                Object.entries(props.overrides).map(([override, overrideProps]) => {
                    
                    // Skip this iteration if this override has already been
                    // included as a linked override.
                    if (renderedOverrides[override]) { return null; }
                    
                    let name = overrideProps.name;

                    // Create linked name.
                    if (overrideProps.linkTo && overrideProps.linkTo.length) {
                        overrideProps.linkTo.map((link) => {
                            name += ', ' + props.overrides[link].name;
                            renderedOverrides[link] = true;
                        });
                    }

                    return (
                        <li key={props.cipher + '-override-' + override}>
                            <Checkbox
                                label={name}
                                onChange={(e) => props.onChangeOverride(e, { cipher: props.cipher, overrides: [...overrideProps.linkTo, override] })}
                                checked={overrideProps.isActive}
                                id={override} // Using ID to pass the override key
                            />
                        </li>
                    )
                })
            }
        </ul>
    )
};

const mapDispatchToProps = (dispatch, payload) => {
    return {
        onChangeOverride: (e, payload) => {
            dispatch(actions.updateOverrideRules(payload))
        }
    }
};

export default connect(null, mapDispatchToProps)(OptionCipherOverride);