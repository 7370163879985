import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';

import * as utils from '../utilities';
import { Modal } from 'semantic-ui-react';

const NumberModal = (props) => (
    <Modal
        onClose={() => props.closeModal(null)}
        open={props.number != null}
        size='tiny'
    >
        <Modal.Header>
            <div className="clearfix">
                <div className="gen-modal-header-title">Number properties for: {props.number}</div>

                <div className="gen-modal-header-icon">
                    <div
                        className="gen-icon-close"
                        onClick={() => props.closeModal(null)}
                    ></div>
                </div>
            </div>
        </Modal.Header>

        <Modal.Content>
            {utils.getNumberContent(props.number)}
        </Modal.Content>
    </Modal>
);

const mapStateToProps = state => ({
    number: state.studyNumber
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        closeModal: (val) => {
            dispatch(actions.changeStudyNumber(val));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberModal);