import React from 'react';
import NumberGematria from './number-gematria';
import NumberReduction from './number-reduction';
import './with-numbers.scss';

const CloseButton = (props) => {
    if (props.close) {
        return (
            <div
                className="gc-close-button"
                onClick={(e) => {
                    console.log('click close!!!!! ', props.index);
                    props.close(props.index)
                }}
            >
                <div className="gen-icon-close"></div>
            </div>
        )
    } else {
        return null;
    }
};

export function withNumbers(Component) {
    return class extends React.Component {
        render() {
            return (
                <div className='gc-with-numbers'>
                    <div className='gc-content'>
                        <Component {...this.props} />
                    </div>

                    <div className='gc-meta'>
                        <div className='gc-values'>
                            <NumberGematria number={this.props.totalValue} />

                            <NumberReduction number={this.props.totalValueReduction} />
                        </div>

                        <CloseButton close={this.props.close} index={this.props.index} />
                    </div>
                </div>
            )
        }
    }
}