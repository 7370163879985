import React from 'react';
import './number-gematria.scss';

import Number from './number';

const NumberGematria = (props) => (
    <div className="gc-number-gematria">
        {/* <span className="gc-label">value</span> */}
        
        <Number number={props.number} />
    </div>
);

export default NumberGematria;