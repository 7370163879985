/**
 * A class that encompasses both the data (`ciphers`), and a collection of
 * helper methods for working with the data. Think of this as the brain of
 * the app, and `reducers.js` as the nervous system.
 * 
 * In regards to data, the app is tiny and the data never changes.
 * So yeah, hard coding ¯\_(ツ)_/¯
 */
const Dictionary = {
    parseText(cipher, text, overrides = {}) {
        let paragraphIndex = 0;
        let wordIndex = 0;
        let paragraphs = [{
            words: [{
                letters: [],
                value: 0
            }]
        }];
        let totalValue = 0;

        // Find the associated value for each letter.
        Array.prototype.map.call(text, (letter) => {

            let letterValue = 0;

            // Check for case sensitivity
            letter = this.ciphers[cipher].isCaseSensative ? letter : letter.toLowerCase();

            if (overrides[letter] && overrides[letter].isActive) {

                // Get override letter value
                letterValue = this.ciphers[cipher].overrides[letter].value;
            } else {

                // Get default letter value
                letterValue = this.ciphers[cipher].map[letter] || 0;
            }

            // If there is no letter value, we _may_ need to increment the
            // index for words, paragraphs, or both.
            if (!letterValue) {

                // If letter is a newline, start new paragraph.
                if (letter.search(/\n/) === 0) {

                    paragraphs.push({
                        words: [{
                            letters: [],
                            value: 0
                        }]
                    });

                    paragraphIndex++;

                    // Reset wordIndex
                    wordIndex = 0;

                    return;
                }

                // if letter is a space, start new word.
                if (letter === ' ') {

                    paragraphs[paragraphIndex].words.push({
                        letters: [],
                        value: 0
                    });

                    wordIndex++;

                    return;
                }
            }

            // Increase total value
            totalValue += letterValue;

            // Increase word value
            paragraphs[paragraphIndex].words[wordIndex].value += letterValue;

            // Add letter/value tuple to word array
            paragraphs[paragraphIndex].words[wordIndex].letters.push([letter, letterValue]);
        });

        return ({
            paragraphs: paragraphs,
            totalValue: totalValue,
            totalValueReduction: this.digitSum(totalValue)
        });
    },

    parseAlphabet(cipher, overrides) {
        let letters = [];
        let type = this.ciphers[cipher].isCaseSensative ? 'lowerUpper' : 'lower';

        Array.prototype.map.call(this.alphabets[type], (letter) => {

            let letterValue = 0;

            if (overrides && overrides[letter] && overrides[letter].isActive) {

                // Get override letter value
                letterValue = this.ciphers[cipher].overrides[letter].value;
            } else {

                // Get default letter value
                letterValue = this.ciphers[cipher].map[letter];
            }

            letters.push([
                letter,
                letterValue
            ]);
        });

        return letters;
    },

    lookup: function (cipher, string) {

        if (!cipher || !string) {
            return;
        }

        let value = 0;

        Array.prototype.map.call(string, (char, i) => {
            value += this.ciphers[cipher].map[char] || 0;
        });

        return value;
    },

    /**
     * Get the sum of the digits that make up a number using Modulo 9 arithmetic.
     * - https://stackoverflow.com/questions/38334652/sum-all-the-digits-of-a-number-javascript
     * - http://www.sjsu.edu/faculty/watkins/Digitsum00.htm
     * 
     * @param {Number} number - The number to sum.
     * @return {Number} - The sum of the individual digits of the passed in number.
     */
    digitSum(number) {
        return (number - 1) % 9 + 1;
    },

    alphabets: {
        lower: 'abcdefghijklmnopqrstuvwxyz',
        lowerUpper: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz'
    },

    ciphers: {
        ordinal: {
            isCaseSensative: false,
            name: "Simple English Gematria (Ordinal Gematria)",
            map: {
                a: 1,
                b: 2,
                c: 3,
                d: 4,
                e: 5,
                f: 6,
                g: 7,
                h: 8,
                i: 9,
                j: 10,
                k: 11,
                l: 12,
                m: 13,
                n: 14,
                o: 15,
                p: 16,
                q: 17,
                r: 18,
                s: 19,
                t: 20,
                u: 21,
                v: 22,
                w: 23,
                x: 24,
                y: 25,
                z: 26
            },
            overrides: {}
        },
        reduction: {
            isCaseSensative: false,
            name: "Pythagorean Gematria (Reduction Gematria)",
            map: {
                a: 1,
                b: 2,
                c: 3,
                d: 4,
                e: 5,
                f: 6,
                g: 7,
                h: 8,
                i: 9,
                j: 1,
                k: 2,
                l: 3,
                m: 4,
                n: 5,
                o: 6,
                p: 7,
                q: 8,
                r: 9,
                s: 1,
                t: 2,
                u: 3,
                v: 4,
                w: 5,
                x: 6,
                y: 7,
                z: 8
            },
            overrides: {
                s: {
                    linkTo: [],
                    name: "S = 10",
                    value: 10
                },
                k: {
                    linkTo: ['v'],
                    name: "K = 11",
                    value: 11
                },
                v: {
                    linkTo: ['k'],
                    name: "V = 22",
                    value: 22
                }
            }
        },
        reverseOrdinal: {
            isCaseSensative: false,
            name: "Reverse Simple English (also called Reverse Ordinal)",
            map: {
                z: 1,
                y: 2,
                x: 3,
                w: 4,
                v: 5,
                u: 6,
                t: 7,
                s: 8,
                r: 9,
                q: 10,
                p: 11,
                o: 12,
                n: 13,
                m: 14,
                l: 15,
                k: 16,
                j: 17,
                i: 18,
                h: 19,
                g: 20,
                f: 21,
                e: 22,
                d: 23,
                c: 24,
                b: 25,
                a: 26
            },
            overrides: {}
        },
        reverseReduction: {
            isCaseSensative: false,
            name: "Reverse Pythagorean (also called Reverse Reduced)",
            map: {
                z: 1,
                y: 2,
                x: 3,
                w: 4,
                v: 5,
                u: 6,
                t: 7,
                s: 8,
                r: 9,
                q: 1,
                p: 2,
                o: 3,
                n: 4,
                m: 5,
                l: 6,
                k: 7,
                j: 8,
                i: 9,
                h: 1,
                g: 2,
                f: 3,
                e: 4,
                d: 5,
                c: 6,
                b: 7,
                a: 8
            },
            overrides: {
                h: {
                    linkTo: [],
                    name: "H = 10",
                    value: 10
                },
                p: {
                    linkTo: ['e'],
                    name: "P = 11",
                    value: 11
                },
                e: {
                    linkTo: ['p'],
                    name: "E = 22",
                    value: 22
                }
            }
        },
        sumerian: {
            isCaseSensative: false,
            name: "Sumerian",
            map: {
                a: 6,
                b: 12,
                c: 18,
                d: 24,
                e: 30,
                f: 36,
                g: 42,
                h: 48,
                i: 54,
                j: 60,
                k: 66,
                l: 72,
                m: 78,
                n: 84,
                o: 90,
                p: 96,
                q: 102,
                r: 108,
                s: 114,
                t: 120,
                u: 126,
                v: 132,
                w: 138,
                x: 144,
                y: 150,
                z: 156
            },
            overrides: {}
        },
        reverseSumerian: {
            isCaseSensative: false,
            name: "Reverse Sumerian",
            map: {
                z: 6,
                y: 12,
                x: 18,
                w: 24,
                v: 30,
                u: 36,
                t: 42,
                s: 48,
                r: 54,
                q: 60,
                p: 66,
                o: 72,
                n: 78,
                m: 84,
                l: 90,
                k: 96,
                j: 102,
                i: 108,
                h: 114,
                g: 120,
                f: 126,
                e: 132,
                d: 138,
                c: 144,
                b: 150,
                a: 156
            },
            overrides: {}
        },
        francisBacon: {
            isCaseSensative: true,
            name: "Francis Bacon",
            map: {
                a: 1,
                b: 2,
                c: 3,
                d: 4,
                e: 5,
                f: 6,
                g: 7,
                h: 8,
                i: 9,
                j: 10,
                k: 11,
                l: 12,
                m: 13,
                n: 14,
                o: 15,
                p: 16,
                q: 17,
                r: 18,
                s: 19,
                t: 20,
                u: 21,
                v: 22,
                w: 23,
                x: 24,
                y: 25,
                z: 26,
                A: 27,
                B: 28,
                C: 29,
                D: 30,
                E: 31,
                F: 32,
                G: 33,
                H: 34,
                I: 35,
                J: 36,
                K: 37,
                L: 38,
                M: 39,
                N: 40,
                O: 41,
                P: 42,
                Q: 43,
                R: 44,
                S: 45,
                T: 46,
                U: 47,
                V: 48,
                W: 49,
                X: 50,
                Y: 51,
                Z: 52
            },
            overrides: {}
        },
        francBaconis: {
            isCaseSensative: true,
            name: "Franc Baconis",
            map: {
                A: 1,
                a: 2,
                B: 3,
                b: 4,
                C: 5,
                c: 6,
                D: 7,
                d: 8,
                E: 9,
                e: 10,
                F: 11,
                f: 12,
                G: 13,
                g: 14,
                H: 15,
                h: 16,
                I: 17,
                i: 18,
                J: 19,
                j: 20,
                K: 21,
                k: 22,
                L: 23,
                l: 24,
                M: 25,
                m: 26,
                N: 27,
                n: 28,
                O: 29,
                o: 30,
                P: 31,
                p: 32,
                Q: 33,
                q: 34,
                R: 35,
                r: 36,
                S: 37,
                s: 38,
                T: 39,
                t: 40,
                U: 41,
                u: 42,
                V: 43,
                v: 44,
                W: 45,
                w: 46,
                X: 47,
                x: 48,
                Y: 49,
                y: 50,
                Z: 51,
                z: 52
            },
            overrides: {}
        },
        jewish: {
            isCaseSensative: false,
            name: "Jewish Gematria",
            map: {
                a: 1,
                b: 2,
                c: 3,
                d: 4,
                e: 5,
                f: 6,
                g: 7,
                h: 8,
                i: 9,
                j: 600,
                k: 10,
                l: 20,
                m: 30,
                n: 40,
                o: 50,
                p: 60,
                q: 70,
                r: 80,
                s: 90,
                t: 100,
                u: 200,
                v: 700,
                w: 900,
                x: 300,
                y: 400,
                z: 500
            },
            overrides: {}
        },
        englishExtended: {
            isCaseSensative: false,
            name: "English Extended",
            map: {
                a: 1,
                b: 2,
                c: 3,
                d: 4,
                e: 5,
                f: 6,
                g: 7,
                h: 8,
                i: 9,
                j: 10,
                k: 20,
                l: 30,
                m: 40,
                n: 50,
                o: 60,
                p: 70,
                q: 80,
                r: 90,
                s: 100,
                t: 200,
                u: 300,
                v: 400,
                w: 500,
                x: 600,
                y: 700,
                z: 800
            },
            overrides: {}
        },
        satanic: {
            isCaseSensative: false,
            name: "Satanic",
            map: {
                a: 36,
                b: 37,
                c: 38,
                d: 39,
                e: 40,
                f: 41,
                g: 42,
                h: 43,
                i: 44,
                j: 45,
                k: 46,
                l: 47,
                m: 48,
                n: 49,
                o: 50,
                p: 51,
                q: 52,
                r: 53,
                s: 54,
                t: 55,
                u: 56,
                v: 57,
                w: 58,
                x: 59,
                y: 60,
                z: 61
            },
            overrides: {}
        },
        septenary: {
            isCaseSensative: false,
            name: "Septenary",
            map: {
                a: 1,
                b: 2,
                c: 3,
                d: 4,
                e: 5,
                f: 6,
                g: 7,
                h: 6,
                i: 5,
                j: 4,
                k: 3,
                l: 2,
                m: 1,
                n: 1,
                o: 2,
                p: 3,
                q: 4,
                r: 5,
                s: 6,
                t: 7,
                u: 6,
                v: 5,
                w: 4,
                x: 3,
                y: 2,
                z: 1
            },
            overrides: {}
        },
        chaldean: {
            isCaseSensative: false,
            name: "Chaldean",
            map: {
                a: 1,
                b: 2,
                c: 3,
                d: 4,
                e: 5,
                f: 8,
                g: 3,
                h: 5,
                i: 1,
                j: 1,
                k: 2,
                l: 3,
                m: 4,
                n: 5,
                o: 7,
                p: 8,
                q: 1,
                r: 2,
                s: 3,
                t: 4,
                u: 6,
                v: 6,
                w: 6,
                x: 5,
                y: 1,
                z: 7
            },
            overrides: {}
        }
    }
};

export default Dictionary;