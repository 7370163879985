import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Button } from 'semantic-ui-react';
import { withNumbers } from '../components/with-numbers';
import Dictionary from '../data/dictionary';
import Paragraph from '../components/paragraph';
import SnapshotList from '../components/snapshot-list';
import AlphabetChart from '../components/alphabet-chart';
import OptionCipherOverride from '../components/option-cipher-override';
import './details.scss';

const TextOutput = (props) => (
    <div className='gc-details-text-output clearfix'>
        {props.paragraphs.map((paragraph, i) => {
            if (!i && paragraph.words[0].letters.length === 0) {
                return (
                    <div key={'p' + i} className="gc-details-empty-message">
                        Input text above to get started.
                    </div>
                );
            } else {
                return (
                    (
                        <Paragraph id={"p" + i} key={"p-" + i} words={paragraph.words} />
                    )
                )
            }
        })}
    </div>
);

const TextOutputWithNumbers = withNumbers(TextOutput);

const Details = (props) => {

    let cipherProps = props.ciphers[props.cipher];

    // Direct back to list if the cipher passed in isn't active
    if (!cipherProps.isActive) {
        props.changeView(null, 'list');
    }

    return (
        <div className={"gc-view gc-view-details gc-view-details-" + props.cipher}>
            <div className="gc-top-menu clearfix">
                <Button
                    content='Back'
                    onClick={(e) => props.changeView(e, 'list')}
                    primary
                />

                <Button
                    className='gc-button-settings'
                    content='Settings'
                    onClick={(e) => props.updateSettingsActive(true)}
                    primary
                />
            </div>

            <div className="gc-details-header clearfix">
                <h2 className='gc-details-heading'>{Dictionary.ciphers[props.cipher].name}</h2>
            </div>

            <TextOutputWithNumbers {...cipherProps} />

            <SnapshotList cipherSnapshots={props.cipherSnapshots[props.cipher]} closable={true} />

            {DetailsChart(props.cipher, cipherProps.overrides, props.showCipherCharts)}

            {DetailsOptions(props.cipher, cipherProps.overrides)}
        </div>
    )
};

const DetailsChart = (cipher, overrides, showCipherCharts) => {
    if (!showCipherCharts) {
        return null;
    }

    return (
        <div className="gc-details-chart clearfix">
            <h3 className='gc-section-heading'>Chart</h3>

            <AlphabetChart cipher={cipher} overrides={overrides} />
        </div>
    )
}

const DetailsOptions = (cipher, overrides) => {

    if (Object.keys(overrides).length === 0) {
        return null;
    }

    return (
        <div className="gc-details-options">
            <h3 className='gc-section-heading'>Options</h3>

            <OptionCipherOverride cipher={cipher} overrides={overrides} />
        </div>
    )
}

const mapStateToProps = state => ({
    activeCiphers: state.activeCiphers,
    ciphers: state.ciphers,
    showCipherCharts: state.showCipherCharts,
    cipherSnapshots: state.cipherSnapshots
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        changeView: (e, view) => {
            dispatch(actions.changeView(view));
        },
        updateSettingsActive: (isActive) => {
            dispatch(actions.updateSettingsActive(isActive));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);