import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';

import GcAppReducers from './reducers';
import 'semantic-ui-css/semantic.min.css';
import './index.scss';
import App from './components/app';

const store = createStore(GcAppReducers);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
)