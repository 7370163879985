import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Button } from 'semantic-ui-react';
import LinkedListItem from '../components/linked-cipher-item';
import { withNumbers } from '../components/with-numbers';
import './list.scss';

const SnapshotText = (props) => (
    <div className="gc-snapshot-content">
        {props.phrase}
    </div>
);

// HOC this sucker
const SnapshotWithNumbers = withNumbers(SnapshotText);

const SnapshotList = (props) => {
    if (!props.savedPhrases.length) return null;

    return (
        <div className="gc-snapshot-container clearfix">
            {props.savedPhrases.map((phrase, a) => {
                let snapshot = props.cipherSnapshots[a];

                if (!snapshot) return null;

                let passedProps = {
                    phrase: phrase,
                    totalValue: snapshot.totalValue,
                    totalValueReduction: snapshot.totalValueReduction
                };

                return <SnapshotWithNumbers {...passedProps} key={`gc-snapshot-${a}`} />;
            })}
        </div>
    )
}

const List = (props) => (
    <div className="gc-view gc-view-list">
        <div className="gc-top-menu clearfix">
            <Button
                className='gc-button-settings'
                content='Settings'
                onClick={(e) => props.updateSettingsActive(true)}
                primary
            />
        </div>

        {Object.entries(props.ciphers).map(([cipher, cipherProps]) => {
            
            if (!cipherProps.isActive) return null;

            return (
                <div className='gc-list-item' key={'cl-' + cipher}>
                    <LinkedListItem cipher={cipher} {...cipherProps} />

                    <SnapshotList savedPhrases={props.savedPhrases} cipherSnapshots={props.cipherSnapshots[cipher]} />
                </div>
            )
        })}
    </div>
);

const mapStateToProps = state => ({
    activeCiphers: state.activeCiphers,
    ciphers: state.ciphers,
    cipherSnapshots: state.cipherSnapshots,
    savedPhrases: state.savedPhrases
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        updateSettingsActive: (isActive) => {
            dispatch(actions.updateSettingsActive(isActive));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(List);