import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';

import ListItem from './list-item';
import './linked-cipher-item.scss';

const LinkedListItem = (props) => {

    if (!props.isActive) {
        return null;
    }

    return (
        <div className='gc-linked-cipher-item clearfix' onClick={(e) => props.changeView(e, props.cipher)}>
            <ListItem {...props} />
        </div>
    )
};

const mapStateToProps = state => ({
    view: state.view,
    cipherSnapshots: state.cipherSnapshots
});

const mapDispatchToProps = (dispatch, props) => {
    return {
        changeView: (e, cipher) => {
            e.preventDefault();
            dispatch(actions.changeView(cipher));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkedListItem);