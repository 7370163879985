import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { Checkbox } from 'semantic-ui-react';

import OptionCipherOverride from './option-cipher-override';

const ItemSettingsCipher = (props) => (
    <li>
        <Checkbox
            label={props.name}
            onChange={(e) => props.onChange(e, { cipher: props.cipher })}
            checked={props.isActive}
        />

        <OptionCipherOverride cipher={props.cipher} overrides={props.overrides} />
    </li>
);

const mapDispatchToProps = (dispatch, payload) => {
    return {
        onChange: (e, payload) => {
            dispatch(actions.updateActiveCipher(payload))
        }
    }
};

export default connect(null, mapDispatchToProps)(ItemSettingsCipher);