import React from 'react';

import fibonacciNumbers from './tables/fibonacciNumbers';
import primeNumbers from './tables/primeNumbers';
import squareNumbers from './tables/squareNumbers';
import triangularNumbers from './tables/triangularNumbers';
import Number from './components/number';

function numberSearch(n, table) {

    // handle n is less than starting value in array
    if (n < table[0]) {
        return [null, null, table[0]];
    }

    for (let i = 0; i < table.length; i++) {

        // n is in array.
        if (n == table[i]) {

            // handle first index
            if (i === 0) {
                return [null, i + 1, table[i + 1]];

            // handle last index
            } else if (i === table.length - 1) {
                return [table[i - 1], i + 1, null];

            // handle everything else
            } else {
                return [table[i - 1], i + 1, table[i + 1]];
            }
        }

        // handle end of array.
        if (i === table.length - 1) {
            return [table[i], null, null];
        }

        // n is not in array.
        if (n > table[i] && n < table[i + 1]) {
            return [table[i], null, table[i + 1]];
        }
    }
}

/**
 * @return {tuple}
 */
function isPrime(n) {
    return numberSearch(n, primeNumbers);
}

/**
 * https://stackoverflow.com/questions/30919111/whats-the-best-way-in-javascript-to-test-if-a-given-parameter-is-a-square-numbe
 * 
 * @return {boolean} Returns true if number is square.
 */
function isSquare(n) {
    return numberSearch(n, squareNumbers);
}

/**
 * https://www.geeksforgeeks.org/check-number-fibonacci-number/
 * 
 * @return {boolean} Return true if number is part of the Fibonacci sequence
 */

function isFibonacci(n) {
    return numberSearch(n, fibonacciNumbers);
}

/**
 * http://theflyingkeyboard.net/algorithms/javascript-check-if-number-is-triangle-number/
 * 
 * @return {boolean} Returns true if number is triangular
 */
function isTriangular(n) {
    return numberSearch(n, triangularNumbers);
}

/**
 * @return {string} Returns formatted text displaying number properties
 */
export function getNumberContent(n) {
    let prime = isPrime(n);
    let square = isSquare(n);
    let fibonacci = isFibonacci(n);
    let triangular = isTriangular(n);

    return (
        <table className='gc-table'>
            <thead>
                <tr>
                    <td className='gc-table-number-label'>Number</td>
                    <td className='gc-table-number'>{n}</td>
                    <td>Prev</td>
                    <td>Next</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><strong>Prime</strong></td>
                    <td>{prime[1] ? getOrdinalSuffix(prime[1]) : 'No'}</td>
                    <td><Number number={prime[0]} /></td>
                    <td><Number number={prime[2]} /></td>
                </tr>

                <tr>
                    <td><strong>Square</strong></td>
                    <td>{square[1] ? getOrdinalSuffix(square[1]) : 'No'}</td>
                    <td><Number number={square[0]} /></td>
                    <td><Number number={square[2]} /></td>
                </tr>

                <tr>
                    <td><strong>Fibonacci</strong></td>
                    <td>{fibonacci[1] ? getOrdinalSuffix(fibonacci[1]) : 'No'}</td>
                    <td><Number number={fibonacci[0]} /></td>
                    <td><Number number={fibonacci[2]} /></td>
                </tr>

                <tr>
                    <td><strong>Triangular</strong></td>
                    <td>{triangular[1] ? getOrdinalSuffix(triangular[1]) : 'No'}</td>
                    <td><Number number={triangular[0]} /></td>
                    <td><Number number={triangular[2]} /></td>
                </tr>
            </tbody>
        </table>
    )
}

/**
 * https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
 * 
 * @return {string} ['st'|'nd'|'rd'|'th'] depending on which one makes
 * grammatical sense given the last digit (or two) in a number.
 */
export function getOrdinalSuffix(n) {
    var j = n % 10,
        k = n % 100;
    if (j == 1 && k != 11) {
        return n + "st";
    }
    if (j == 2 && k != 12) {
        return n + "nd";
    }
    if (j == 3 && k != 13) {
        return n + "rd";
    }
    return n + "th";
}