import React from 'react';
import { withNumbers } from './with-numbers';

const Title = ({ name }) => (
    <span className='gc-name'>{name}</span>
);

const ItemWithNumbers = withNumbers(Title);

const ListItem = (props) => {
    return (
        <ItemWithNumbers {...props} />
    )
};

export default ListItem;