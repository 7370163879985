import React from 'react';

import Dictionary from '../data/dictionary';
import Number from './number';
import './alphabet-chart.scss';

const AlphabetChart = (props) => {

    let letters = Dictionary.parseAlphabet(props.cipher, props.overrides);

    return (
        <div className="gc-alphabet-chart">
            {
                Array.prototype.map.call(letters, (letter) => (
                    <div key={letter[0] + letter[1]} className='gc-chart-cell'>
                        <div className='gc-chart-letter'>{letter[0]}</div>
                        
                        <div className='gc-chart-value'><Number number={letter[1]} /></div>
                    </div>
                ))
            }
        </div>
    )
}

export default AlphabetChart;