import React from 'react';
import { ReactComponent as Logo } from './logo.svg';
import './footer.scss';

const Footer = (props) => (
    <footer className="gc-wrap gc-footer clearfix">
        <span className="gc-footer-copyright">
            Copyright © {(new Date().getFullYear())} Gematria Effect News
        </span>

        <span className="gc-footer-logo">
            <Logo />
        </span>
    </footer>
)

export default Footer;