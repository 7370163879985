import React from 'react';
import { connect } from 'react-redux';

import Details from '../views/details';
import List from '../views/list';
import UserInput from './user-input';
import Footer from '../components/footer';
import SettingsModal from '../components/settings-modal';
import NumberModal from '../components/number-modal';

class App extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            value: '',
            words: []
        };
    }

    onChange = (e) => {
        this.setState({
            value: e.target.value,
            words: e.target.value.match(/[A-Za-z'-]+/g) || []
        });
    }

    render() {
        return (
            <div className="gc-main-container">
                <div className="gc-wrap">
                    <UserInput />
                    
                    {chooseView(this.props.view)}
                </div>

                <Footer />

                <SettingsModal />

                <NumberModal />
            </div>
        )
    }
}

function chooseView(view) {
    if (view === 'list') {
        return <List />;
    } else {
        return <Details cipher={view} />;
    }
}

const mapStateToProps = state => ({
    view: state.view
});

export default connect(mapStateToProps)(App);