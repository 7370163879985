import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import './number.scss';

const Number = (props) => {
    if (props.number != null) {
        return (
            <span className='gc-number' onClick={(e) => props.onClickNumber(e)}>
                {props.number}
            </span>
        )
    } else {
        return null;
    }
};

// Map store dispatch to component props
const mapDispatchToProps = (dispatch, props) => {
    return {
        onClickNumber: (e) => {
            dispatch(actions.changeStudyNumber(e.target.textContent));
        }
    }
}

export default connect(null, mapDispatchToProps)(Number);
